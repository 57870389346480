import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { PolicyFormatter } from '@wix/bookings-uou-mappers';
import { ServicePolicy } from '@wix/bookings-uou-types';
import { TimeSlotAvailabilityStatus } from '../../../../utils/timeSlots/timeSlots';
import { Optional } from '../../../../types/types';
import settingsParams from '../../settingsParams';

export enum TimePickerNotificationType {
  ALL_SESSIONS_ARE_FULL = 'ALL_SESSIONS_ARE_FULL',
  ALL_SESSIONS_ARE_CLOSED = 'ALL_SESSIONS_ARE_CLOSED',
  ALL_SESSIONS_ARE_NOT_YET_OPEN = 'ALL_SESSIONS_ARE_NOT_YET_OPEN',
  SOME_SESSIONS_ARE_CLOSED = 'SOME_SESSIONS_ARE_CLOSED',
  SOME_SESSIONS_ARE_NOT_YET_OPEN = 'SOME_SESSIONS_ARE_NOT_YET_OPEN',
}

export type TimePickerNotificationViewModel = {
  notificationType?: TimePickerNotificationType;
  messageText?: string;
  ctaText?: string;
};

type TimePickerNotificationViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>;
};

export function createTimePickerNotificationViewModel({
  timeSlotsAvailabilityStatuses,
  state,
  context,
}: TimePickerNotificationViewModelParams): Optional<TimePickerNotificationViewModel> {
  const {
    selectedService: { policy },
  } = state;
  const { t, settings } = context;

  const {
    minutesBeforeSlotBookWindowStart,
    minutesBeforeSlotBookWindowEnd,
  } = getFormattedPolicyLimits(policy, t);
  const notificationOptions = getNotificationOptions(
    timeSlotsAvailabilityStatuses,
  );

  if (notificationOptions.allSessionsAreFull) {
    return {
      notificationType: TimePickerNotificationType.ALL_SESSIONS_ARE_FULL,
      messageText: settings.get(
        settingsParams.timePickerAllSessionsAreFullNotificationText,
      ),
      ctaText: settings.get(settingsParams.timePickerGoToNextAvailableLinkText),
    };
  } else if (notificationOptions.allSessionsAreClosed) {
    return {
      notificationType: TimePickerNotificationType.ALL_SESSIONS_ARE_CLOSED,
      messageText: t('app.time-picker.notifications.all-sessions-are-closed', {
        duration: minutesBeforeSlotBookWindowEnd,
      }),
    };
  } else if (notificationOptions.allSessionsAreNotOpenYet) {
    return {
      notificationType:
        TimePickerNotificationType.ALL_SESSIONS_ARE_NOT_YET_OPEN,
      messageText: t(
        'app.time-picker.notifications.all-sessions-are-not-open-yet',
        {
          duration: minutesBeforeSlotBookWindowStart,
        },
      ),
    };
  } else if (notificationOptions.someSessionsAreClosed) {
    return {
      notificationType: TimePickerNotificationType.SOME_SESSIONS_ARE_CLOSED,
      messageText: t('app.time-picker.notifications.some-sessions-are-closed', {
        duration: minutesBeforeSlotBookWindowEnd,
      }),
    };
  } else if (notificationOptions.someSessionsAreNotOpenYet) {
    return {
      notificationType:
        TimePickerNotificationType.SOME_SESSIONS_ARE_NOT_YET_OPEN,
      messageText: t(
        'app.time-picker.notifications.some-sessions-are-not-open-yet',
        {
          duration: minutesBeforeSlotBookWindowStart,
        },
      ),
    };
  }
}

const getNotificationOptions = (
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>,
) => {
  const initialNotificationOptions = {
    allSessionsAreFull: true,
    allSessionsAreClosed: true,
    allSessionsAreNotOpenYet: true,
    someSessionsAreClosed: false,
    someSessionsAreNotOpenYet: false,
  };
  return Array.from(timeSlotsAvailabilityStatuses).reduce(
    (notificationOptions, [, currentTimeSlotStatus]) => {
      const {
        allSlotsAreFull,
        tooEarlyToBookAllSlots,
        tooLateToBookAllSlots,
      } = currentTimeSlotStatus;
      const {
        allSessionsAreFull,
        allSessionsAreNotOpenYet,
        allSessionsAreClosed,
        someSessionsAreClosed,
        someSessionsAreNotOpenYet,
      } = notificationOptions;
      return {
        allSessionsAreFull: allSessionsAreFull && allSlotsAreFull,
        allSessionsAreClosed: allSessionsAreClosed && tooLateToBookAllSlots,
        allSessionsAreNotOpenYet:
          allSessionsAreNotOpenYet && tooEarlyToBookAllSlots,
        someSessionsAreClosed: someSessionsAreClosed || tooLateToBookAllSlots,
        someSessionsAreNotOpenYet:
          someSessionsAreNotOpenYet || tooEarlyToBookAllSlots,
      };
    },
    initialNotificationOptions,
  );
};

const getFormattedPolicyLimits = (policy: ServicePolicy, t: TFunction) => {
  const {
    minutesBeforeSlotBookWindowStart,
    minutesBeforeSlotBookWindowEnd,
  } = policy;

  const servicePolicyTranslations = {
    days: t('app.time-picker.notifications.policy.days'),
    day: t('app.time-picker.notifications.policy.day'),
    hours: t('app.time-picker.notifications.policy.hours'),
    hour: t('app.time-picker.notifications.policy.hour'),
    minutes: t('app.time-picker.notifications.policy.minutes'),
    minute: t('app.time-picker.notifications.policy.minute'),
    and: t('app.time-picker.notifications.policy.and'),
  };

  const policyFormatter = new PolicyFormatter(servicePolicyTranslations);

  return {
    minutesBeforeSlotBookWindowStart: policyFormatter.getPolicyLimit(
      minutesBeforeSlotBookWindowStart,
    ),
    minutesBeforeSlotBookWindowEnd: policyFormatter.getPolicyLimit(
      minutesBeforeSlotBookWindowEnd,
    ),
  };
};
