import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarErrors, SetError } from '../../../../utils/bi/consts';

export type SetCalendarErrors = (
  calendarErrors: CalendarErrors,
  setError: SetError,
) => void;

export function createSetCalendarErrorsAction({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>): SetCalendarErrors {
  return (calendarError: CalendarErrors, setError: SetError) => {
    const [state, setState] = getControllerState();
    const { calendarErrors: prevCalendarErrors } = state;

    let calendarErrors: CalendarErrors[] = prevCalendarErrors;
    const isErrorExistOnCalendarErrors = prevCalendarErrors.includes(
      calendarError,
    );

    if (setError === SetError.ADD && !isErrorExistOnCalendarErrors) {
      calendarErrors.push(calendarError);
    } else if (setError === SetError.REMOVE && isErrorExistOnCalendarErrors) {
      calendarErrors = prevCalendarErrors.filter(
        (error) => error !== calendarError,
      );
    }

    setState({
      calendarErrors,
    });
  };
}
