import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import getToastType, { ToastType } from '../../../../utils/state/getToastType';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import { CalendarErrors, SetError } from '../../../../utils/bi/consts';

export type OnToastClose = () => void;

export function createOnToastCloseAction(
  { getControllerState }: ActionFactoryParams<CalendarState, CalendarContext>,
  setCalendarErrorsAction: SetCalendarErrors,
): OnToastClose {
  return () => {
    const [state, setState] = getControllerState();
    const toastType = getToastType(state);

    switch (toastType) {
      case ToastType.RescheduleError:
        setCalendarErrorsAction(
          CalendarErrors.RESCHEDULE_SERVER_ERROR,
          SetError.REMOVE,
        );
        break;
      default:
        break;
    }
  };
}
