import { BookingsServer } from '@wix/ambassador-bookings-server/http';
import { CheckoutServer } from '@wix/ambassador-checkout-server/http';
import {
  BulkRequest,
  BulkResponse,
  ServicesCatalogServer,
  GetActiveFeaturesResponse,
} from '@wix/ambassador-services-catalog-server/http';
import {
  mapCatalogServiceResponseToService,
  mapResponseToBusinessInfo,
} from '@wix/bookings-uou-mappers';
import { BusinessInfoBase, CatalogData } from '@wix/bookings-uou-types';
import { mockGetDateAvailabilityResponse } from '../../__tests__/mocks/ambassador/mockGetDateAvailabilityResponse';
import { SlotsAvailabilityFilter } from './types';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Optional } from '../types/types';
import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
} from '@wix/ambassador-availability-calendar/types';
import { AvailabilityCalendar } from '@wix/ambassador-availability-calendar/http';
import { getEndOfDay } from '../utils/dateAndTime/dateAndTime';

interface CatalogDataFilter {
  serviceSlug?: string;
}

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const BOOKINGS_SERVER_URL = '_api/bookings';
export const AVAILABILITY_SERVER_URL = '_api/availability-calendar';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';

export class BookingsApi {
  private authorization: string;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private bookingsServer: ReturnType<typeof BookingsServer>;
  private availabilityCalendarServer: ReturnType<typeof AvailabilityCalendar>;
  private checkoutServer: ReturnType<typeof CheckoutServer>;

  constructor({
    authorization,
    baseUrl,
  }: {
    authorization: string;
    baseUrl: string;
  }) {
    this.authorization = authorization;
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.availabilityCalendarServer = AvailabilityCalendar(
      `${baseUrl}${AVAILABILITY_SERVER_URL}`,
    );
    this.bookingsServer = BookingsServer(`${baseUrl}${BOOKINGS_SERVER_URL}`);
    this.checkoutServer = CheckoutServer(`${baseUrl}${CHECKOUT_SERVER_URL}`);
  }

  async getCatalogData({
    serviceSlug,
  }: CatalogDataFilter): Promise<CatalogData> {
    const servicesCatalogService = this.catalogServer.Bulk();

    const bulkRequest: BulkRequest = this.createBulkRequest({ serviceSlug });
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: this.authorization,
    }).get(bulkRequest);

    const services = catalogData.responseServices!.services!.map((service) =>
      mapCatalogServiceResponseToService(service),
    );
    const businessInfo: BusinessInfoBase = mapResponseToBusinessInfo(
      catalogData.responseBusiness!,
    );
    const activeFeatures: GetActiveFeaturesResponse = catalogData.responseBusiness!
      .activeFeatures!;

    return { services, businessInfo, activeFeatures };
  }

  async getDateAvailability(
    slotsAvailabilityFilter: SlotsAvailabilityFilter,
  ): Promise<any> {
    // TODO: getNextAvailableDate API
    /* Response for Demo */
    const dateAvailability = mockGetDateAvailabilityResponse();
    return new Promise<any>((resolve) =>
      setTimeout(() => resolve(dateAvailability), 1000),
    );
  }

  async getSlotsForSelectedDate(
    slotsAvailabilityFilter: SlotsAvailabilityFilter,
  ): Promise<QueryAvailabilityResponse> {
    const {
      from,
      service,
      filterOptions,
      onlyAvailableSlots,
    } = slotsAvailabilityFilter;
    const availabilityCalendarService = this.availabilityCalendarServer.AvailabilityCalendar();
    const to = getEndOfDay(from);
    const availabilityCalendarRequest: QueryAvailabilityRequest = {
      query: {
        filter: {
          serviceIds: [`${service?.id}`],
          from: `${from}.000Z`,
          to,
          ...(onlyAvailableSlots === true
            ? { isBookable: onlyAvailableSlots }
            : {}),
          ...(filterOptions.STAFF_MEMBER?.length > 0
            ? { resourceId: filterOptions.STAFF_MEMBER }
            : {}),
          ...(filterOptions.LOCATION?.length > 0
            ? { 'location.businessLocation.id': filterOptions.LOCATION }
            : {}),
        },
      },
    };

    const availability: QueryAvailabilityResponse = await availabilityCalendarService(
      {
        Authorization: this.authorization,
      },
    ).queryAvailability(availabilityCalendarRequest);

    return availability;
  }

  async getBookingDetails(bookingId: string) {
    const response = await this.bookingsServer
      .BookingsReader()({ Authorization: this.authorization })
      .query({
        withBookingAllowedActions: true,
        query: {
          filter: JSON.stringify({ bookingId }),
          fields: [],
          fieldsets: [],
          sort: [],
        },
      });

    return response.bookingsEntries![0].booking;
  }

  async getPricingPlanDetails(
    rfcStartTime: string,
    serviceId: string,
  ): Promise<Optional<PaidPlans>> {
    const response = await this.checkoutServer
      .CheckoutBackend()({ Authorization: this.authorization })
      .checkoutOptions({
        createSession: {
          scheduleOwnerId: serviceId,
          start: {
            timestamp: rfcStartTime,
          },
        },
        paymentSelection: {
          numberOfParticipants: 1,
        },
      });
    return response.checkoutOptions?.paidPlans;
  }

  async rescheduleClassBooking({
    bookingId,
    sessionId,
  }: {
    bookingId: string;
    sessionId: string;
  }) {
    const response = await this.bookingsServer
      .Bookings()({ Authorization: this.authorization })
      .reschedule({
        bookingId,
        bySessionId: {
          sessionId,
        },
      });

    return response;
  }

  async rescheduleAppointmentBooking({
    bookingId,
    scheduleId,
    timezone,
    start,
    end,
    staffMembersScheduleIds,
  }: {
    bookingId: string;
    scheduleId: string;
    timezone: string;
    start: string;
    end: string;
    staffMembersScheduleIds: string[];
  }) {
    return this.bookingsServer
      .Bookings()({ Authorization: this.authorization })
      .reschedule({
        bookingId,
        createSession: {
          scheduleId,
          start: {
            timeZone: timezone,
            timestamp: start,
          },
          end: {
            timeZone: timezone,
            timestamp: end,
          },
          affectedSchedules: staffMembersScheduleIds.map((id) => ({
            scheduleId: id,
          })),
        },
      });
  }

  private createBulkRequest({ serviceSlug }: CatalogDataFilter): BulkRequest {
    const bulkRequest: BulkRequest = {
      requestServices: {
        includeDeleted: false,
        query: {
          fieldsets: [],
          filter: serviceSlug ? `{"slugs.name": "${serviceSlug}"}` : null,
          paging: {
            limit: 500,
          },
          fields: [],
          sort: [],
        },
      },
      requestBusiness: {
        suppressNotFoundError: false,
      },
    };
    return bulkRequest;
  }
}
