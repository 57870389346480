import { Booking } from '@wix/ambassador-bookings-server/types';
import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  CalendarViewModel,
  createCalendarViewModel,
  createDummyCalendarViewModel,
} from './ViewModel/viewModel';
import { CalendarActions, createCalendarActions } from './Actions/actions';
import { CatalogData, Service } from '@wix/bookings-uou-types';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utils/context/contextFactory';
import { CalendarApi } from '../../api/CalendarApi';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { createInitialState } from '../../utils/state/initialStateFactory';
import { BottomSectionStatus } from './ViewModel/widgetViewModel/widgetViewModel';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { TimePickerStatus } from './ViewModel/timePickerViewModel/timePickerViewModel';
import { CalendarErrors, TriggeredByOptions } from '../../utils/bi/consts';
import {
  DialogState,
  DialogType,
} from './ViewModel/dialogViewModel/dialogViewModel';
import { FilterTypes } from './ViewModel/filterViewModel/filterViewModel';
import { SelectedBookingPreference } from '../../utils/bookingPreferences/bookingPreferences';
import { FlowElements } from './Hooks/useFlow';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  bottomSectionStatus: BottomSectionStatus;
  timePickerStatus: TimePickerStatus;
  selectedService: Service;
  selectedDate?: string;
  selectedTimezone?: string;
  selectedMonth?: string;
  dateAvailability?: any;
  selectedTime?: string;
  bookableSlotsAtSelectedTime?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  selectedBookingPreferences: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
  dialog?: {
    type: DialogType;
    state: DialogState;
  };
  filterOptions: {
    [key in keyof typeof FilterTypes]: string[];
  };
  focusedElement?: FlowElements;
};

// For more info about controller structure,
// check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  return {
    async pageReady() {
      const { controllerConfig } = flowAPI;
      const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
      const calendarApi = new CalendarApi({ wixSdkAdapter });
      const {
        services: [service],
        businessInfo,
      }: CatalogData = await calendarApi.getCatalogData();

      const initialState: CalendarState = createInitialState(
        service,
        wixSdkAdapter,
      );

      const calendarContext: CalendarContext = createCalendarContext(
        flowAPI,
        businessInfo,
        calendarApi,
        wixSdkAdapter,
        initialState,
      );

      const { onStateChange } = await createControlledComponent<
        CalendarState,
        CalendarActions,
        CalendarViewModel,
        CalendarContext
      >(
        controllerConfig,
        initialState,
        wixSdkAdapter.isEditorMode()
          ? createDummyCalendarViewModel
          : createCalendarViewModel,
        createCalendarActions,
        calendarContext,
      );

      const { biLogger } = calendarContext;

      biLogger.bookingsCalendarFiltersLoad({
        triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
        selectedFilters: JSON.stringify(initialState.filterOptions),
      });
      if (!wixSdkAdapter.isSSR()) {
        onStateChange((state) => {
          biLogger.update(state);
        });
      }
    },
  };
};

export default createController;
