import {
  BookingPreference,
  BookingPreferenceOption,
  SelectedBookingPreference,
} from '../bookingPreferences/bookingPreferences';

export const isSelectedBookingPreferenceWithWaitingList = (
  selectedPreference: SelectedBookingPreference,
  bookingPreferences: BookingPreference[],
): boolean => {
  const bookingPreference = getBookingPreferenceFromSelectedPreference(
    selectedPreference,
    bookingPreferences,
  );
  return !!bookingPreference?.options.some(
    (bookingPreferenceOption: BookingPreferenceOption) =>
      bookingPreferenceOption.id == selectedPreference.value &&
      bookingPreferenceOption.isWithWaitingList,
  );
};

const getBookingPreferenceFromSelectedPreference = (
  selectedPreference: SelectedBookingPreference,
  bookingPreferences: BookingPreference[],
) => {
  return bookingPreferences.filter(
    (bookingPreference: BookingPreference) =>
      bookingPreference.key === selectedPreference.key,
  )[0];
};
