import { CalendarState } from '../../components/BookingCalendar/controller';
import { CalendarErrors } from '../bi/consts';

// eslint-disable-next-line no-shadow
export enum ToastType {
  RescheduleError = 'reschedule-error',
}

export default function getToastType(
  state: CalendarState,
): ToastType | undefined {
  const { calendarErrors } = state;

  if (calendarErrors.includes(CalendarErrors.RESCHEDULE_SERVER_ERROR)) {
    return ToastType.RescheduleError;
  }
}
