import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import {
  formatRfcTimeStringToTimeSlotView,
  formatShortDate,
} from '../../../../utils/dateAndTime/dateAndTime';

export enum DialogState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

export type DialogViewModel = {
  isOpen: boolean;
  titleText: string;
  contentText: string;
  confirmButtonText: string;
  state: DialogState;
};

// eslint-disable-next-line no-shadow
export enum DialogType {
  RescheduleConfirm = 'reschedule-confirm',
}

export function createDialogViewModel({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>): DialogViewModel {
  const { dialog } = state;

  return {
    isOpen: !!dialog,
    state: dialog?.state || DialogState.IDLE,
    ...getTexts({ state, context }),
  };
}

function getTexts({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>) {
  const { dialog, selectedTime } = state;
  const { t, businessInfo } = context;

  if (dialog?.type === DialogType.RescheduleConfirm) {
    return {
      titleText: t('app.rescheduled-booking.dialog.title'),
      contentText: t('app.rescheduled-booking.dialog.content', {
        date: formatShortDate(
          selectedTime!,
          businessInfo.dateRegionalSettingsLocale,
        ),
        time: formatRfcTimeStringToTimeSlotView(
          selectedTime!,
          businessInfo.dateRegionalSettingsLocale,
        ),
      }),
      confirmButtonText: t('app.rescheduled-booking.dialog.button'),
    };
  }
  return {
    titleText: '',
    contentText: '',
    confirmButtonText: '',
  };
}
